import './App.css';
import React from 'react'
import { useRef } from "react";
import ReactPlayer from 'react-player'


function App() {

  const playerRef = useRef();
  const cuePoints = [
    {titre: "I. Introduction", time: {min:1,sec: 27}},
    {titre: "II. L'oralité", time: {min:6,sec: 18}},
    {titre: "III. La créolisation et la décolonisation", time: {min:12,sec: 46}},
    {titre: "IV. Fragments d'histoire de l'lle de la Réunion (lle Bourbon)", time: {min:14,sec: 50}},
    {titre: "V. L'histoire en lien avec Madagascar, lle Rouge", time: {min:28,sec: 50}},
    {titre: "VI. L'histoire de mes parents", time: {min:33,sec: 6}},
    {titre: " - De ma mère", time: {min:33,sec: 6}},
    {titre: " - De mon père", time: {min:47,sec: 12}},
    {titre: "VII. La décolonisation", time: {min:55,sec: 52}},
    {titre: "VIII. Conclusion", time: {min:58,sec: 16}}
  ]
  const handleClick = (e) => {
    console.log(e.target.id)
    const cp = cuePoints[e.target.id].time
    console.log(cp)
    playerRef.current?.seekTo((60.0 * cp.min) + cp.sec);
  }


  const Button = ({ type = 'button', onClick, children, ...rest }) => {
    return (
      <button type={type} onClick={onClick} {...rest}>
        {children}
      </button>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <img
          src="cover.png"
          width={'75%'}
        />
        <ReactPlayer
          ref={playerRef}
          url='audio.mp3'
          controls={true}
          height={40}
          width={'75%'}
        />
        <div className="App-button">
          {cuePoints.map((x, i) =>
            <Button key={i} id={i} onClick={handleClick} children={cuePoints[i].titre}/>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
